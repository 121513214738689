import React, { memo, useState } from 'react';
import { useListAdSetsQuery } from '../../../../../data/api/fbAdsApi';
import Loader from '../../../../../Components/Loader';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import ProcessAdCreationModal from '../FbModals/ProcessAdCreationModal';

const FbAdSets = memo(({ state, setState, fetchedVideos }) => {
  let campaign = state?.selectedCampaign;
  const [selectedAdSet, setSelectedAdSet] = useState(null);
  const { data: adSets, isLoading } = useListAdSetsQuery(campaign?.id, {
    skip: !campaign?.id,
  });
  const handleOpenModal = (adSet) => {
    setSelectedAdSet(adSet);
  };
  const handleCloseModal = () => {
    setSelectedAdSet(null);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover align-middle rounded overflow-hidden bg-white tap-n-table">
            <thead>
              <tr>
                <th>AdSet Name</th>
                <th>Action</th>
                <th>Status</th>
                <th>Start Time</th>
                <th>Daily Budget</th>
                <th>Lifetime Budget</th>
                <th>Budget Remaining</th>
                <th>Campaign ID</th>
              </tr>
            </thead>
            <tbody>
              {!!adSets?.length ? (
                adSets.map((item) => (
                  <tr className="cursor-pointer" key={item.id}>
                    <td>{item.name}</td>
                    <td>
                      <Button onClick={() => handleOpenModal(item)}>Create Ads</Button>
                    </td>
                    <td>{item.status}</td>
                    <td>{moment(item.start_time).format('ll')}</td>
                    <td>{item.daily_budget}</td>
                    <td>{item.lifetime_budget}</td>
                    <td>{item.budget_remaining}</td>
                    <td>{item.campaign_id}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No active AdSets found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {selectedAdSet && (
        <ProcessAdCreationModal
          state={{ ...state }}
          adset={selectedAdSet}
          setState={setState}
          handleClose={handleCloseModal}
          fetchedVideos={fetchedVideos}
        />
      )}
    </>
  );
});

export default FbAdSets;
