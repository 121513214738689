import { generatePath, Link, useRouteMatch, useParams, Switch, useHistory } from 'react-router-dom';
import {
  R_GAME_ABTESTS,
  R_GAME_CONFIGS,
  R_GAME_DRAFT,
  R_GAME_FBADS,
  R_GAME_INFO,
  R_GAME_METRICS,
  R_GAMES,
} from '../../../constants/routes';
import GameMetrics from './Metrics/GameMetrics';
import GameInfo from './Info/GameInfo';
import GuardedRoute from '../../../Modules/GuardedRoute';
import EmptyLayout from '../../../Layouts/EmptyLayout';
import GameAbtestList from '../../AbTest/GameAbtestList/GameAbtestList';
import UpdateDraftGameModal from '../UpdateDraftGame/UpdateDraftGameModal';
import DraftGameInfo from './Info/DraftGameInfo';
import PublishDraftGame from '../UpdateDraftGame/PublishDraftGame';
import AbTestCreateBtns from '../../AbTest/CreateAbTestModal/AbTestCreateBtns';
import React, { useEffect } from 'react';
import { useGetGamesQuery } from '../../../data/api/studioApi';
import { getGameById } from '../../../data/slices/studio';
import { useSelector } from 'react-redux';
import { Restricted } from '../../../Components/permissions/UserPermissions';
import FbAds from './FbCampaigns/FbAds';

function Game() {
  const history = useHistory();
  let { id } = useParams();
  const isDraft = useRouteMatch(R_GAME_DRAFT)?.isExact;
  const isConfig = useRouteMatch(R_GAME_CONFIGS)?.isExact;
  const { data: games, isFetching } = useGetGamesQuery(undefined, { skip: isDraft });
  let foundGame = useSelector(getGameById(id));
  useEffect(() => {
    if (games && !isFetching && !foundGame) history.push(`/`);
  }, [games]);

  const showCreateTest =
    !!foundGame && (foundGame.id === Number(id) || foundGame?.ios?.id === Number(id))
      ? foundGame.status !== 'ARCHIVED' || foundGame.ios?.status !== 'ARCHIVED'
      : false;

  return (
    <div className="container-fluid mb-4">
      <div className="row top-bar mb-4 px-md-2 bg-white">
        <div className="col-12 d-flex align-items-center">
          <div className="top-bar-tab-btns">
            <Link
              className={`btn ${useRouteMatch(R_GAME_METRICS)?.isExact ? 'active' : isDraft ? 'disabled' : ''}`}
              to={generatePath(R_GAME_METRICS, { id: id })}
            >
              Metrics
            </Link>
            <Link
              className={`btn ${useRouteMatch(R_GAME_INFO)?.isExact || isDraft ? 'active' : ''}`}
              to={isDraft ? '#' : generatePath(R_GAME_INFO, { id: id })}
              id="game-information"
            >
              Information
            </Link>
            <Link
              className={`btn ${useRouteMatch(R_GAME_ABTESTS)?.isExact ? 'active' : isDraft ? 'disabled' : ''}`}
              to={generatePath(R_GAME_ABTESTS, { id: id })}
            >
              A/B Tests
            </Link>
            <Link
              className={`btn ${isConfig ? 'active' : isDraft ? 'disabled' : ''}`}
              to={generatePath(R_GAME_CONFIGS, { id: id })}
            >
              Configs
            </Link>
            <Restricted permission="fb.ads">
              <Link
                className={`btn ${useRouteMatch(R_GAME_FBADS)?.isExact ? 'active' : isDraft ? 'disabled' : ''}`}
                to={generatePath(R_GAME_FBADS, { id: id })}
              >
                FB Ads
              </Link>
            </Restricted>
          </div>
          <div className="ms-auto d-flex">
            {isDraft ? (
              <>
                <div className="me-3">
                  <PublishDraftGame
                    onSuccess={(game) => {
                      setTimeout(() => {
                        history.push(generatePath(R_GAME_INFO, { id: game.id }));
                      }, 1500);
                    }}
                    showCount
                    id={id}
                  />
                </div>
                <UpdateDraftGameModal
                  onDelete={() => {
                    history.push(generatePath(R_GAMES, { filter: 'draft' }));
                  }}
                  id={id}
                />
              </>
            ) : (
              showCreateTest && <AbTestCreateBtns type={isConfig ? 'CONFIG' : 'ABTEST'} id={id} />
            )}
          </div>
        </div>
      </div>
      <Switch>
        <GuardedRoute
          layout={EmptyLayout}
          title="Pending Game Info"
          back={generatePath(R_GAMES, { filter: 'draft' })}
          path={R_GAME_DRAFT}
          component={DraftGameInfo}
        />
        <GuardedRoute
          layout={EmptyLayout}
          title="Game Info"
          back={generatePath(R_GAMES, { filter: null })}
          path={R_GAME_INFO}
          component={GameInfo}
        />
        <GuardedRoute
          layout={EmptyLayout}
          title="Game A/B Tests"
          back={generatePath(R_GAMES, { filter: null })}
          path={R_GAME_ABTESTS}
          disableTopBar
          type="ABTEST"
          component={(props) => (
            <div className="row">
              <GameAbtestList {...props} />
            </div>
          )}
        />
        <GuardedRoute
          layout={EmptyLayout}
          title="Game Configs"
          back={generatePath(R_GAMES, { filter: null })}
          path={R_GAME_CONFIGS}
          disableTopBar
          type="CONFIG"
          component={(props) => (
            <div className="row">
              <GameAbtestList {...props} />
            </div>
          )}
        />
        <GuardedRoute
          layout={EmptyLayout}
          title="Game FbAds"
          back={generatePath(R_GAMES, { filter: null })}
          path={R_GAME_FBADS}
          disableTopBar
          component={FbAds}
        />
        <GuardedRoute
          layout={EmptyLayout}
          title="Game Metrics"
          back={generatePath(R_GAMES, { filter: null })}
          component={GameMetrics}
        />
      </Switch>
    </div>
  );
}

export default Game;
