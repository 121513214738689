import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useListClientsQuery } from '../../../../../data/api/fbAdsApi';

function FBCustomerClientList({ selected, onSelect, length }) {
  const { data: clients, isLoading } = useListClientsQuery();

  const handleSelectChange = (e) => {
    const selectedClient = clients.find((client) => client.id === e.target.value);
    if (selectedClient) {
      onSelect && onSelect({ id: selectedClient.id, name: selectedClient.name });
    }
  };

  if (isLoading) return <div>Loading client...</div>;

  return (
    <Form.Group as={Col} xs={length || 6} controlId="clientSelect" className="d-flex align-items-center gap-3">
      <strong className="text-nowrap">Choose Client</strong>
      <Form.Select value={selected?.id || ''} onChange={handleSelectChange}>
        <option value="">Select Client Account</option>
        {clients?.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default FBCustomerClientList;
