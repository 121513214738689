import { Col, Form } from 'react-bootstrap';
import { useListCampaignsQuery } from '../../../../../data/api/fbAdsApi';

const CampaignList = ({ state, setState }) => {
  const clientID = state.client?.id;
  const { data: campaigns, isFetching: isLoadingCampaigns } = useListCampaignsQuery(clientID, {
    skip: !clientID,
  });

  if (isLoadingCampaigns) return <div>Loading campaigns...</div>;
  const activeCampaigns = (campaigns || []).filter((el) => el?.effective_status === 'ACTIVE');

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const campaign = campaigns.find((campaign) => campaign.id === selectedId);
    setState({ selectedCampaign: campaign, hasChanged: true });
  };

  return (
    <>
      {!!activeCampaigns.length ? (
        <Form.Group controlId="campaignSelect" xs={6} className="d-flex align-items-center gap-3">
          <strong className="text-nowrap ">Select a Campaign</strong>
          <Form.Select size="sm" className="flex-grow-1" onChange={handleSelectChange} aria-label="Select campaign">
            <option value="">Select a Campaign</option>
            {activeCampaigns.map((campaign) => (
              <option key={campaign.id} value={campaign.id}>
                {campaign.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      ) : (
        'No active campaigns found'
      )}
    </>
  );
};

export default CampaignList;
