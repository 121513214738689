export const R_LOGIN = '/login';
export const R_SIGN_UP = '/sign-up';
export const R_FORGOT_PASSWORD = '/forgot-password';
export const R_TERMS_CONDITIONS = '/general-terms-and-conditions';

export const R_DASHBOARD = '/';
export const R_HOME = R_DASHBOARD;
export const R_GAMES = '/games/:filter?';
export const R_SDK = '/sdk';
export const R_MARKET_INSIGHT = '/market-insight';
export const R_TOP_APPS = '/top-apps';
export const R_AI_TOOLS = '/ai';
export const R_AI_CHAT = '/ai/chat';
export const R_AI_IMAGE_GENERATOR = '/ai/image-generator';
export const R_AI_IMAGE_VARIATIONS = '/ai/image-variations';
export const R_AI_GAME_PITCH = '/ai/game-pitch';
export const R_USER_MANAGEMENT = '/user-management';
export const R_UM_ACCOUNTS = R_USER_MANAGEMENT + '/accounts';
export const R_UM_STUDIOS = R_USER_MANAGEMENT + '/studios';

export const R_GAME = '/game/:id';
export const R_GAME_METRICS = R_GAME + '/metrics';
export const R_GAME_INFO = R_GAME + '/info';
export const R_GAME_ABTESTS = R_GAME + '/ab-tests';
export const R_GAME_CONFIGS = R_GAME + '/configs';
export const R_GAME_FBADS = R_GAME + '/fbads';
export const R_GAME_DRAFT = R_GAME + '/draft';

export const R_AB_TESTS = '/abtests';
export const R_AB_TEST = R_AB_TESTS + '/:id/:type?';
export const R_AB_TEST_METRICS = '/abtest-results/:gameId/:testId';

export const R_ACCOUNT = '/account';
export const R_ANALYTICS_MONETIZATION = '/analytics/monetization';
export const R_ANALYTICS_ACQUISITION = '/analytics/acquisition';
export const R_ANALYTICS_ENGAGEMENT = '/analytics/engagement';
export const R_OVERVIEW_ANALYTICS = '/analytics/overview/report';
