import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { useState, useCallback } from 'react';
import { useCreateAdMutation, useCreateCreativeMutation, useGetVideoMutation } from '../../../../../data/api/fbAdsApi';
import CheckmarkIcon from '../../../../../Components/Icons/CheckmarkIcon';
import CancelIcon from '../../../../../Components/Icons/CancelIcon';

function ProcessAdCreationModal({ state, setState, adset, handleClose, fetchedVideos }) {
  const [processing, setProcessing] = useState({});
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [createCreative] = useCreateCreativeMutation();
  const [createAds] = useCreateAdMutation();
  const [getVideo] = useGetVideoMutation();
  const toggleSelectVideo = (videoId) => {
    setSelectedVideos((prev) => {
      return prev.includes(videoId) ? prev.filter((id) => id !== videoId) : [...prev, videoId];
    });
  };

  const process = useCallback(async () => {
    setErrorMessages([]);

    const videosToProcess = state.videos.filter(
      (vid) => vid.clientId === state.client?.id && selectedVideos?.includes(vid.originalId),
    );
    // get video data /marketing/facebook/videos/:video_id?
    for (let video of videosToProcess) {
      setProcessing((val) => ({
        ...val,
        [video.originalId]: 'processing',
      }));
      let vid = await getVideo(video.video_id)
        .unwrap()
        .then((resp) => {
          return resp[0];
        })
        .catch((er) => {
          setProcessing((val) => ({
            ...val,
            [vid.id]: 'failed',
            [vid.id + '_error']: er?.error_user_title + ' ' + er?.error_data,
          }));
          setErrorMessages((prev) => [
            ...prev,
            `${vid.name || vid.title}: ${er?.error_user_title || 'An error occurred during fb video fetch'}`,
          ]);
        });
      if (!vid) continue;
      const payload = {
        name: vid.name || vid.title,
        object_story_spec: {
          page_id: state.pageId,
          video_data: {
            call_to_action: {
              type: 'PLAY_GAME',
              value: { link: adset?.promoted_object?.object_store_url },
            },
            image_url: vid.picture || vid.icon,
            video_id: vid.video_id || vid.id,
          },
        },
      };

      await createCreative({
        adset: adset?.id,
        accountId: state.client?.id,
        data: payload,
      })
        .unwrap()
        .then((resp) => {
          if (resp.error) throw resp.error;
          const adPayload = {
            name: vid.name || vid.title,
            adset_id: adset.id,
            creative: { creative_id: resp.id },
            status: 'ACTIVE',
          };
          return createAds({ adset: adset.id, accountId: state.client.id, data: adPayload }).unwrap();
        })
        .then((resp) => {
          if (resp.error) throw resp.error;
          setProcessing((val) => ({
            ...val,
            [video.originalId]: 'success',
          }));
        })
        .catch((er) => {
          setProcessing((val) => ({
            ...val,
            [video.originalId]: 'failed',
            [video.originalId + '_error']: er?.error_user_title + ' ' + er?.error_user_msg,
          }));
          setErrorMessages((prev) => [...prev, `${video.originalId}: ${er?.error_user_title || 'An error occurred.'}`]);
        });
    }
  }, [
    state.adSets,
    state.client?.id,
    createAds,
    createCreative,
    state.appUrl,
    state.pageId,
    state.videos,
    selectedVideos,
  ]);

  const filteredVideos = fetchedVideos.filter((video) =>
    state.videos.some((v) => v.originalId === video.id && v.clientId === state.client?.id),
  );
  return (
    <Modal show size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ad Creation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {adset && (
          <div key={adset.id}>
            <p>Adset Name: {adset?.name}</p>
            <div className="mb-4 pb-3 border-bottom ps-4 d-flex overflow-hidden overflow-x-scroll">
              {filteredVideos.map((video) => (
                <div className="video-container position-relative" key={video.id}>
                  <div className="video-wrapper position-relative">
                    {video && (
                      <video
                        id={`video-${video.id}`}
                        controls
                        preload="metadata"
                        className="rounded"
                        src={typeof video.file === 'string' ? video.file : URL.createObjectURL(video.file)}
                      />
                    )}
                    Video ID: {video.originalId}
                  </div>
                  <div className="">
                    {processing?.[video.id] === 'success' ? (
                      <CheckmarkIcon size={20} color={'green'} />
                    ) : processing?.[video.id] === 'failed' ? (
                      <>
                        <CancelIcon size={20} color="#ff3f5c" />
                        <div className="text-danger">{processing?.[video.id + '_error']}</div>
                      </>
                    ) : processing?.[video.id] === 'processing' ? (
                      <Spinner />
                    ) : (
                      <label
                        className="position-absolute m-2 z-3 cursor-pointer top-0 end-0"
                        style={{ padding: '50%', backgroundColor: 'transparent' }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedVideos.includes(video.id)}
                          onChange={() => toggleSelectVideo(video.id)}
                          style={{ position: 'absolute', top: '0', left: '15px' }}
                        />
                      </label>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {errorMessages.length > 0 && (
          <div className="alert alert-danger">
            {errorMessages.map((msg, index) => (
              <p key={index}>{msg}</p>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={process} disabled={!selectedVideos.length}>
          Create Ads
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProcessAdCreationModal;
