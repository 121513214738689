import { useState } from 'react';
import { useListPagesQuery } from '../../../../../data/api/fbAdsApi';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';

const FbPagesList = ({ value, onChange, length }) => {
  const { data: pages } = useListPagesQuery({});
  const [selectedPageId, setSelectedPageId] = useState(value);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPageId(selectedValue);

    const selectedPage = pages?.find((page) => page.id === selectedValue);
    const pageName = selectedPage ? selectedPage.name : '';

    onChange({ pageId: selectedValue, pageName });
  };

  return (
    <div className="py-2">
      <Form>
        <Form.Group as={Col} xs={length || 6} className="d-flex align-items-center gap-3">
          <strong className="text-nowrap">Select Facebook Page</strong>
          <Form.Select value={selectedPageId} onChange={handleChange}>
            <option value="" disabled>
              Select FACEBOOK Page
            </option>
            {pages?.map((page) => (
              <option key={page.id} value={page.id}>
                {page.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Form>
    </div>
  );
};

export default FbPagesList;
