import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useCreateCampaignMutation } from '../../../../../data/api/fbAdsApi';

function CreateCampaignModal({ clientId, closeModal, onSuccess }) {
  const [createCampaignMutation, { isLoading, isSuccess }] = useCreateCampaignMutation();
  const [campaignName, setCampaignName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSave = () => {
    setErrorMessage('');

    createCampaignMutation({
      clientId,
      name: campaignName,
      objective: 'OUTCOME_APP_PROMOTION',
      status: 'ACTIVE',
      special_ad_categories: ['NONE'],
    })
      .unwrap()
      .then((newCampaign) => {
        if (newCampaign.error) {
          let msg = `${newCampaign.error?.errorUserTitle}: ${newCampaign.error?.errorUserMsg}`;
          setErrorMessage(
            newCampaign.error?.errorUserTitle
              ? msg
              : newCampaign.error.message ?? 'Failed to create campaign. Please try again.',
          );
        } else {
          onSuccess({ id: newCampaign.id, name: campaignName });
          closeModal();
        }
      })
      .catch((error) => {
        console.error('Failed to create campaign:', error);
        setErrorMessage('Failed to create campaign. Please try again.');
      });
  };

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formCampaignName">
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter campaign name"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </Form.Group>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Creating...' : 'Create Campaign'}
        </Button>
      </Modal.Footer>
      {!errorMessage && isSuccess && <p className="text-success mt-2">Campaign created successfully!</p>}
    </Modal>
  );
}

export default CreateCampaignModal;
