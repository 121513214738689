import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import UploadIcon from '../../../../Components/Icons/UploadIcon';
import { Button } from 'react-bootstrap';
import CancelIcon from '../../../../Components/Icons/CancelIcon';
import CloudDownlaod from '../../../../Components/Icons/CloudDownLoad';
import DownloadArrow from '../../../../Components/Icons/DownloadArrow';
import '../../CreateGame/gameCrud.scss';
import Loader from '../../../../Components/Loader';
import { useDownloadFileMutation } from '../../../../data/api/googleCloudApi';
import { Restricted } from '../../../../Components/permissions/UserPermissions';
import { confirmAlert } from '../../../../Components/ConfirmModal';

const VideoView = ({ videos, setVideos, gameStatus }) => {
  const [downloadFile] = useDownloadFileMutation();
  const [toWaitDL, setToWaitDL] = useState([]);
  const [error, setError] = useState('');

  const handleVideoAdd = (event) => {
    setError('');
    if (event.target.files.length === 0) {
      return false;
    }
    let newVideos = [];
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      if (file.size > 1000000000) {
        confirmAlert({
          variant: 'danger',
          title: 'File size must not exceed 1GB',
          confirmBtn: false,
          cancelText: 'Ok',
        }).catch((err) => {});
      } else {
        if (
          videos?.find((el) => {
            if (typeof el?.file === 'string') {
              let tmp = el.file.split('/');
              if (tmp[tmp.length - 1] === file.name) {
                return true;
              }
            }
            return el.file.name === file.name;
          })
        ) {
          confirmAlert({
            variant: 'danger',
            title: "You can't upload the same file twice",
            confirmBtn: false,
            cancelText: 'Ok',
          }).catch((err) => {});
        } else {
          newVideos.push({ type: 'rush', file });
        }
      }
    }
    setVideos([...videos, ...newVideos]);
  };
  const handleDelete = (index) => {
    let tmp = [...videos];
    tmp.splice(index, 1);
    setVideos(tmp);
  };
  const handleDownload = async (value, index) => {
    if (typeof value === 'string') {
      setToWaitDL((oldArray) => [...oldArray, index]);
      const urlTab = value.split('https://storage.googleapis.com/');
      const urlSplit = value.split('/');
      const fileName = `${urlSplit[urlSplit.length - 2]}/${urlSplit[urlSplit.length - 1]}`;
      const downloadPromise = downloadFile(urlTab[1]).unwrap();
      downloadPromise
        .then((blob) => {
          setToWaitDL((prev) => prev.filter((e) => e !== index));
          const alink = document.createElement('a');
          alink.href = URL.createObjectURL(blob);
          alink.download = fileName;
          alink.click();
          URL.revokeObjectURL(alink.href);
        })
        .catch((error) => {
          console.error('Download error:', error);
          setToWaitDL((prev) => prev.filter((e) => e !== index));
        });
    }
  };

  const handleDLAll = () => {
    [...videos].map(async (el, index) => await handleDownload(el.file, index));
  };
  const handleTypeChange = (value, index) => {
    if (value) {
      let tmp = [...videos];
      tmp[index] = { ...tmp[index], type: value };
      setVideos(tmp);
    }
  };
  return (
    <div>
      <div className="d-flex mb-3">
        <Form.Label>Upload Videos</Form.Label>
        {!!videos.length && (
          <Button size="sm" variant="info" className="ms-auto" onClick={handleDLAll}>
            <DownloadArrow className="me-2" />
            Download All Videos
          </Button>
        )}
      </div>
      {error && <p className="alert alert-danger">{error}</p>}

      {videos.length ? (
        <div className="d-flex overflow-scroll">
          {videos.map(
            (value, index) =>
              value.file && (
                <div className="video-container" key={index}>
                  <video
                    controls
                    preload="metadata"
                    className="rounded"
                    src={typeof value?.file === 'string' ? value.file : URL.createObjectURL(value.file)}
                  />
                  <Restricted permission={'game.video.update'}>
                    <Form.Group className="input-group select-drop">
                      <Form.Select
                        className="form-select"
                        value={value.type}
                        onChange={(e) => handleTypeChange(e.target.value, index)}
                      >
                        <option value={'rush'}>Raw video</option>
                        <option value={'crea'}>Creative</option>
                        <option value={'ready'}>Ready</option>
                      </Form.Select>
                    </Form.Group>
                  </Restricted>
                  <Button
                    variant="danger"
                    className="position-absolute top-0 end-0 mt-1 me-1 py-1"
                    onClick={() => {
                      handleDelete(index);
                    }}
                    disabled={gameStatus === 'ARCHIVED'}
                  >
                    <CancelIcon size={'16px'} color="currentColor" />
                  </Button>

                  <Button
                    variant="info"
                    className="position-absolute top-0 start-0 ms-1 mt-1 py-1"
                    onClick={() => {
                      handleDownload(value.file, index);
                    }}
                    disabled={!!toWaitDL.find((e) => e === index)}
                  >
                    {toWaitDL.includes(index) ? (
                      <Loader parentStyle="" size={16} color={'#002cff'} />
                    ) : (
                      <CloudDownlaod size={'16'} color="currentColor" />
                    )}
                  </Button>
                </div>
              ),
          )}
          <Restricted permission={'game.video.create'}>
            <label htmlFor="rush-button-file" className="add-more-card rounded fs-7 text-success cursor-pointer">
              <input
                accept="video/*"
                type="file"
                onChange={handleVideoAdd}
                multiple
                id="rush-button-file2"
                style={{ display: 'none' }}
              />
              <div className="btn btn-success">+</div>
              Add more Videos
            </label>
          </Restricted>
        </div>
      ) : (
        <Restricted permission={'game.video.create'} fallback={'No videos.'}>
          <label
            htmlFor="rush-button-file"
            className="w-100 bg-light rounded
            cursor-pointer
                    text-muted  fs-7 text-center
                    d-flex flex-column  justify-content-center"
            style={{ minHeight: 200 }}
          >
            <div className="mb-1">
              <UploadIcon />
            </div>
            Drag & Drop Video Files <br />
            or <br />
            <span className="btn-link text-primary">Choose File</span>
          </label>
        </Restricted>
      )}
      <Restricted permission={'game.video.create'}>
        <input
          multiple
          accept="video/*"
          type="file"
          onChange={handleVideoAdd}
          id="rush-button-file"
          style={{ display: 'none' }}
        />
      </Restricted>
    </div>
  );
};

export default VideoView;
