import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CampaignList from './FbLists/CampaignList';
import FBCustomerClientList from './FbLists/FBCustomerClientList';
import { getGameById } from '../../../../data/slices/studio';
import {
  useGetFacebookSettingsQuery,
  useGetGameVideosQuery,
  useUpdateFacebookSettingsMutation,
} from '../../../../data/api/studioApi';
import Loader from '../../../../Components/Loader';
import { Button } from 'react-bootstrap';
import EditIcon from '../../../../Components/Icons/EditIcon';
import FbVideosModal from './FbModals/FbVideosModal';
import { useSetState } from '../../../../utils/customHooks';
import FbAdSets from './FbLists/FbAdSets';
import FbPagesList from './FbLists/FbPagesList';
import CreateAdsetModal from './FbModals/CreateAdsetModal';
import CreateCampaignModal from './FbModals/CreateCampaignModal';
import FbAppIDModal from './FbApp/FbAppIDModal';

function FbAds() {
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  const [modalType, setModalType] = useState(null);
  const [state, setState] = useSetState({
    adSets: [],
    appUrl: '',
    client: null,
    createAdSet: [],
    createCampaign: [],
    pageName: '',
    pageId: '',
    hasChanged: false,
    selectedCampaign: null,
    videos: [],
  });
  const { data: savedSettings, isLoading: isLoadingSettings } = useGetFacebookSettingsQuery(foundGame?.appId, {
    skip: !foundGame?.appId,
  });
  const { data: fetchedVideos } = useGetGameVideosQuery(
    { appId: foundGame?.appId, studioId: foundGame?.studioId },
    { skip: !foundGame?.appId },
  );

  const [updateFacebookSettings] = useUpdateFacebookSettingsMutation();

  useEffect(() => {
    const parsedValue = savedSettings?.value ? JSON.parse(savedSettings.value) : null;
    if (parsedValue) {
      setState({ ...parsedValue, hasChanged: false });
    }
  }, [savedSettings, setState]);

  const saveSettings = () => {
    updateFacebookSettings({
      appId: foundGame?.appId,
      settings: state,
    })
      .unwrap()
      .then(() => {
        console.log('Settings saved successfully');
      })
      .catch((error) => console.error('Failed to save settings:', error))
      .finally(() => setState({ hasChanged: false }));
  };

  useEffect(() => {
    if (state.hasChanged) {
      saveSettings();
    }
  }, [state.hasChanged]);

  if (isLoadingSettings) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
        <Loader parentStyle="loader" size={50} color="#3F96C7" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      {!foundGame ? (
        <div className="d-flex justify-content-center w-100">
          <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
        </div>
      ) : !foundGame?.fbAppId ? (
        <FbAppIDModal data={foundGame} />
      ) : (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-start gap-2">
              {modalType !== 'client' && state?.client?.name ? (
                <>
                  <strong className="text-end" style={{ minWidth: 160 }}>
                    Ad account:
                  </strong>
                  {state?.client?.name}
                  <Button
                    variant="outline-light"
                    size="sm"
                    className="p-1 text-nowrap d-flex align-items-center gap-1"
                    onClick={() => setModalType('client')}
                  >
                    <EditIcon color="currentColor" size={18} /> Change Client
                  </Button>
                </>
              ) : (
                <FBCustomerClientList
                  selected={state.client}
                  onSelect={(client) => {
                    setState({ client, selectedCampaign: null, hasChanged: true });
                    setModalType(null);
                  }}
                  length={12}
                />
              )}
              {fetchedVideos && state?.client?.name && (
                <Button size="sm" onClick={() => setModalType('videos')} className="text-nowrap">
                  Upload videos to FB
                </Button>
              )}
            </div>
          </div>

          <div className="d-flex align-items-start gap-2">
            {modalType !== 'fbPageList' && state.pageName ? (
              <>
                <strong className="text-end" style={{ minWidth: 160 }}>
                  Facebook Page:
                </strong>
                {state.pageName}
                <Button
                  variant="outline-light"
                  size="sm"
                  className="p-1 text-nowrap d-flex align-items-center gap-1"
                  onClick={() => setModalType('fbPageList')}
                >
                  <EditIcon color="currentColor" size={18} /> Change Facebook Page
                </Button>
              </>
            ) : (
              <FbPagesList
                value={state.pageId}
                onChange={({ pageId, pageName }) => {
                  setState({ pageId, pageName, hasChanged: true });
                }}
                length={12}
              />
            )}
          </div>
          {state.client && state.pageId && (
            <>
              <div className="d-flex align-items-start gap-2">
                {modalType === 'campaignList' ? (
                  <CampaignList state={state} setState={setState} />
                ) : (
                  <div className="d-flex gap-2">
                    <strong className="text-end" style={{ minWidth: 160 }}>
                      Campaign:
                    </strong>
                    {state.selectedCampaign?.name}
                    <Button
                      variant="outline-light"
                      size="sm"
                      className="p-1 text-nowrap d-flex align-items-center gap-1"
                      onClick={() => setModalType('campaignList')}
                    >
                      <EditIcon color="currentColor" size={18} /> Select Campaign
                    </Button>
                  </div>
                )}

                <Button size="sm" onClick={() => setModalType('campaign')}>
                  or Add New
                </Button>
              </div>

              {state.selectedCampaign && (
                <>
                  <div className="my-2 d-flex align-items-center">
                    <Button onClick={() => setModalType('adSet')}>Add AdSet</Button>
                  </div>
                  <FbAdSets
                    selected={state.adSets}
                    onSelect={(updatedAdSets) => setState({ adSets: updatedAdSets })}
                    fetchedVideos={fetchedVideos}
                    state={state}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
      {modalType === 'videos' && (
        <FbVideosModal
          handleClose={() => setModalType(null)}
          state={state}
          setState={setState}
          videos={fetchedVideos}
        />
      )}
      {modalType === 'adSet' && (
        <CreateAdsetModal
          closeModal={() => setModalType(null)}
          campaign={state.selectedCampaign}
          client={state.client}
          onSuccess={(adSet) => {
            setState({
              createAdSet: [...(state.createAdSet || []), adSet],
              hasChanged: true,
            });
          }}
        />
      )}
      {modalType === 'campaign' && (
        <CreateCampaignModal
          clientId={state.client?.id}
          closeModal={() => setModalType(null)}
          onSuccess={(campaign) =>
            setState({
              createCampaign: [...(state.createCampaign || []), campaign],
              selectedCampaign: campaign,
              hasChanged: true,
            })
          }
        />
      )}
    </div>
  );
}

export default FbAds;
