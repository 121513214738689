import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hmac256 } from '../../utils/common';
const baseUrl = process.env.REACT_APP_GOOGLE_CLOUD_API;

export const googleCloudApi = createApi({
  reducerPath: 'googleCloudApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      //headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['gameConfig'],
  keepUnusedDataFor: 600, // keep for 10min
  endpoints: (builder) => ({
    uploadCloudVideo: builder.mutation({
      query: ({ appId, studioId, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `/upload/tapnation-studios-videos/${studioId}/${appId}`,
          method: 'POST',
          body: formData,
          formData: true,
          headers: {
            Authorization: hmac256('file=' + file?.name),
          },
        };
      },
    }),
    deleteCloudVideo: builder.mutation({
      query: (fileUrl) => ({
        url: `?file=${encodeURIComponent(fileUrl)}`,
        method: 'delete',
        headers: {
          Authorization: hmac256(baseUrl + `?file=${encodeURIComponent(fileUrl)}`),
        },
      }),
    }),
    downloadPdf: builder.query({
      query: (pdfurl) => {
        return {
          url: `/market_insight/download_pdf?file=${encodeURIComponent(pdfurl)}`,
          headers: {
            Authorization: hmac256(baseUrl + `/market_insight/download_pdf?file=${encodeURIComponent(pdfurl)}`),
          },
          responseHandler: async (response) => {
            const reader = response.body.getReader();
            const stream = new ReadableStream({
              start(controller) {
                return pump();
                function pump() {
                  return reader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                      controller.close();
                      return;
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return pump();
                  });
                }
              },
            });
            let tmp = new Response(stream);
            let blob = await tmp.blob();
            return URL.createObjectURL(blob);
          },
        };
      },
    }),
    downloadFile: builder.mutation({
      query: (pdfurl) => {
        return {
          url: `/?file=${encodeURIComponent(pdfurl)}`,
          headers: {
            Authorization: hmac256(baseUrl + `/?file=${encodeURIComponent(pdfurl)}`),
          },
          responseHandler: async (response) => {
            const reader = response.body.getReader();
            const stream = new ReadableStream({
              start(controller) {
                return pump();
                function pump() {
                  return reader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                      controller.close();
                      return;
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return pump();
                  });
                }
              },
            });
            let tmp = new Response(stream);
            return tmp.blob();
          },
        };
      },
    }),
    getGameConfig: builder.query({
      query: ({ studioId, bundleId }) => ({
        url: `/game_config/${studioId}/${bundleId}`,
        headers: {
          Authorization: hmac256(baseUrl + `/game_config/${studioId}/${bundleId}`),
        },
      }),
      providesTags: (result, error, { studioId, bundleId }) =>
        result ? [{ type: 'gameConfig', id: studioId + bundleId }] : [],
    }),

    createGameConfig: builder.mutation({
      query: ({ studioId, bundleId, configData }) => ({
        url: `/game_config/${studioId}/${bundleId}`,
        method: 'POST',
        body: configData,
        headers: {
          Authorization: hmac256(configData),
        },
      }),
      invalidatesTags: (result, error, { studioId, bundleId }) => {
        console.log('invalidatesTags', result, error, studioId, bundleId);
        return result ? [{ type: 'gameConfig', id: studioId + bundleId }] : [];
      },
    }),
  }),
});

export const {
  useUploadCloudVideoMutation,
  useDeleteCloudVideoMutation,
  useDownloadFileMutation,
  useDownloadPdfQuery,
  useGetGameConfigQuery,
  useCreateGameConfigMutation,
} = googleCloudApi;
