import { Button, Modal, Form } from 'react-bootstrap';
import React, { useState, useMemo, useEffect } from 'react';
import { useDownloadFileMutation } from '../../../../../data/api/googleCloudApi';
import { useUploadVideosMutation } from '../../../../../data/api/fbAdsApi';
import Loader from '../../../../../Components/Loader';
import UploadIcon from '../../../../../Components/Icons/UploadIcon';
import { confirmAlert } from '../../../../../Components/ConfirmModal';

function FbVideosModal({ isFetching, videos, state, setState, handleClose }) {
  const [selectedType, setSelectedType] = useState('');
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [uploadVideos] = useUploadVideosMutation();
  const [downloadFile] = useDownloadFileMutation();
  const [loadingVideos, setLoadingVideos] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [uploadedVideos, setUploadedVideos] = useState(new Set());
  const client = state.client || state.currentSettings?.client;
  const uniqueTypes = videos.reduce((acc, video) => {
    if (!acc.includes(video.type)) acc.push(video.type);
    return acc;
  }, []);

  const newUploadedVideos = [];

  const filteredVideos = useMemo(
    () =>
      selectedType
        ? videos.filter(
            (video) =>
              video.type === selectedType &&
              state.videos.find((v) => v.originalId === video.id && v.clientId === state.client?.id),
          )
        : videos,
    [selectedType, videos, state.videos],
  );

  useEffect(() => {
    const initialFileNames = {};
    videos.forEach((video) => {
      const urlSplit = video.file.split('/');
      const lastPart = urlSplit[urlSplit.length - 1];
      initialFileNames[video.id] = lastPart;
    });
    setFileNames(initialFileNames);
  }, [videos]);

  const handleTypeChange = (event) => setSelectedType(event.target.value);

  const toggleSelectVideo = (videoId) => {
    if (!uploadedVideos.has(videoId)) {
      setSelectedVideos((prevSelected) =>
        prevSelected.includes(videoId) ? prevSelected.filter((id) => id !== videoId) : [...prevSelected, videoId],
      );
    }
  };

  const handleDownloadAndUpload = async (video) => {
    const urlTab = video.file.split('https://storage.googleapis.com/');
    const videoPath = urlTab[1];
    setLoadingVideos((prev) => [...prev, video.id]);

    return new Promise((resolve) => {
      downloadFile(videoPath)
        .unwrap()
        .then((blob) => {
          const videoData = { file: new File([blob], fileNames[video.id]), accountId: client.id, videoId: video.id };
          return uploadVideos(videoData).unwrap();
        })
        .then((result) => {
          if (result?.error) {
            console.error('Upload failed:', result.error);
            confirmAlert({
              variant: 'danger',
              title: 'Upload failed',
              desc: result.error?.data?.message || result.error?.message || null,
              confirmBtn: false,
              cancelText: 'Ok',
            }).catch(() => {});
          } else {
            console.log('Upload succeeded:', result);
            setUploadedVideos((prev) => new Set(prev).add(video.id));
  
            newUploadedVideos.push({ ...result, clientId: state.client.id, originalId: video.id });
          }
        })
        .catch((error) => {
          console.error('Error processing video:', error);
        })
        .finally(() => {
          setLoadingVideos((prev) => prev.filter((id) => id !== video.id));
          resolve();
        });

    });
  };

  const handleSelectedDownload = async () => {
    const prms = filteredVideos?.map(async (video) => {
      if (selectedVideos.includes(video.id)) {
        return handleDownloadAndUpload(video);
      }
    });

    await Promise.allSettled(prms);

    setState({
      hasChanged: true,
      videos: [...(state.videos || []), ...newUploadedVideos],
    });
  };

  return (
    <Modal show onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Game Videos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <p>Upload Videos</p>
          <Form.Select aria-label="Filter by Type" onChange={handleTypeChange} className="mb-3 w-25">
            <option value="">All Types</option>
            {uniqueTypes.map((type, index) => (
              <option value={type} key={index}>
                {type}
              </option>
            ))}
          </Form.Select>
        </div>
        {isFetching ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
            <Loader parentStyle="loader" size={50} color="#3F96C7" />
          </div>
        ) : filteredVideos.length > 0 ? (
          <div className="d-flex overflow-scroll">
            {filteredVideos.map((video) =>
              video.file ? (
                <div className="video-container position-relative" key={video.id}>
                  <div className="video-wrapper position-relative">
                    <video
                      id={`video-${video.id}`}
                      controls
                      preload="metadata"
                      className="rounded"
                      src={typeof video.file === 'string' ? video.file : URL.createObjectURL(video.file)}
                      style={{ opacity: loadingVideos.includes(video.id) ? 0.4 : 1 }}
                    />
                    {loadingVideos.includes(video.id) && (
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <Loader size={50} color="#3F96C7" />
                      </div>
                    )}
                    <div className="text-nowrap overflow-hidden">
                      {uploadedVideos.has(video.id) ? (
                        <span style={{ fontSize: '13px', color: 'green' }}>Uploaded:{fileNames[video.id]}</span>
                      ) : (
                        <label
                          className="position-absolute top-0 start-0 m-2 z-3 cursor-pointer"
                          style={{ padding: '50%', backgroundColor: 'transparent' }}
                        >
                          <input
                            type="checkbox"
                            className="me-2"
                            checked={selectedVideos.includes(video.id)}
                            onChange={() => toggleSelectVideo(video.id)}
                            style={{ position: 'absolute', top: '0', left: '0' }}
                          />
                        </label>
                      )}
                      {!uploadedVideos.has(video.id) && (
                        <span style={{ fontSize: '13px' }}>
                          {video.type}: {fileNames[video.id]}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ) : null,
            )}
          </div>
        ) : (
          <p>No videos available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="dark"
          onClick={handleSelectedDownload}
          disabled={loadingVideos.length > 0}
          className="d-flex align-items-center gap-2"
        >
          <UploadIcon size="17" color="#fff" />
          {loadingVideos.length > 0 ? 'Uploading...' : 'Upload Selected Videos'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FbVideosModal;
