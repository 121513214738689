import React, { useState } from 'react';
import Videos from './Videos';
import { useParams } from 'react-router-dom';
import { useGetGameMetricsQuery, useListStudiosQuery } from '../../../../data/api/studioApi';
import Form from 'react-bootstrap/Form';
import Loader from '../../../../Components/Loader';
import GameInfoWidget from './GameInfoWidget';
import EmailSendModal from '../../../../Modules/EmailSendModal';
import VideoTips from './VideoTips';
import { Restricted, useUserPermission } from '../../../../Components/permissions/UserPermissions';
import { useSelector } from 'react-redux';
import { getStudioByStudioId } from '../../../../data/slices/studio';
import { getGameById } from '../../../../data/slices/studio';
import GameConfigs from '../../GameConfigs/GameConfigs';

const GameInfo = () => {
  const [os, setOs] = useState('android');

  let { id } = useParams();
  let foundGame = useSelector(getGameById(id));
  const selectStudioPermission = useUserPermission('select.studio');
  const { isLoading: isLoadingGames } = useGetGameMetricsQuery(id);
  useListStudiosQuery(undefined, {
    skip: !selectStudioPermission,
  });

  const foundStudio = useSelector(getStudioByStudioId(foundGame?.studioId));

  return isLoadingGames ? (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
      <Loader parentStyle="loader" size={50} color={'#3F96C7'} />
    </div>
  ) : (
    foundGame?.appName && (
      <>
        <div className="row g-4">
          {foundGame.multipleOs && (
            <div className="col-12 d-flex">
              <div
                className="d-flex align-items-center cursor-pointer ms-auto"
                onClick={() => setOs(os === 'android' ? 'ios' : 'android')}
              >
                <div className="fs-7 text-muted me-1">Android</div>
                <Form>
                  <Form.Check type="switch" checked={os === 'ios'} id="custom-switch" />
                </Form>
                <div className="fs-7 text-muted" id="parot-ios-icon">
                  IOS
                </div>
              </div>
            </div>
          )}
          <div className="col-md-6 col-lg-4 col-12">
            <GameInfoWidget data={os === 'ios' ? foundGame.ios : foundGame} />
            <div className="d-flex align-items-center gap-2">
              <Restricted permission="sdk.config">
                <GameConfigs
                  os={foundGame?.multipleOs ? 'android,ios' : foundGame.os}
                  studioId={foundStudio?.studioId}
                  bundleId={foundGame?.bundleId}
                />
              </Restricted>
              <Restricted permission="send.email">
                <EmailSendModal to={foundStudio?.email} />
              </Restricted>
            </div>
          </div>
          <div className="col-md-6  col-lg-8 col-12">
            <div className="bg-white rounded px-4 py-3">
              <h6 className="fs-7">Game Videos</h6>
              <Videos infosGame={os === 'ios' ? foundGame.ios : foundGame} />
            </div>
          </div>
        </div>
        <VideoTips defaultlyOpen={false} />
      </>
    )
  );
};

export default GameInfo;
