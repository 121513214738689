import React from 'react';
import { Restricted } from '../../../../Components/permissions/UserPermissions';
import { useSelector } from 'react-redux';
import { getStudioByStudioId } from '../../../../data/slices/studio';
import StudioIcon from '../../../../Components/Icons/StudioIcon';
import { capitalizeFirstLetter } from '../../../../utils/converters';
import GameInfoCard from '../../../../Components/GameInfoCard';
import GameStatusDropdown from '../../GameList/GamesListTable/GameStatusDropdown';
import HeaderInfoCard from '../../../AbTest/_Components/HeaderInfoCard';
import CardIcon from '../../../../Components/Icons/CardIcon';
import PeopleGroupIcon from '../../../../Components/Icons/PeopleGroupIcon';
import CalendarIcon from '../../../../Components/Icons/CalendarIcon';
import moment from 'moment';
import InfosPublisherModal from '../../GameList/GamesListTable/InfosPublisherModal';
import GameAnalyticsModal from './GameAnalytics/GameAnalyticsModal';
import FbAppIDModal from '../FbCampaigns/FbApp/FbAppIDModal';
import EditFbAppModal from '../FbCampaigns/FbApp/EditFbAppModal';

const GameInfoWidget = ({ data, handleStatusChange }) => {
  const foundStudio = useSelector(getStudioByStudioId(data?.studioId));

  return (
    <div className="bg-white rounded">
      <GameInfoCard game={{ ...data }} />
      <div className="px-3 mb-1">
        <span className="badge bg-info fs-7 me-1 mb-1">{data?.category}</span>
        {data?.tags &&
          data?.tags.split(',').map((el) => (
            <span key={el} className="badge bg-info fs-7 me-1 mb-1">
              {el}
            </span>
          ))}
      </div>
      <div className="pb-3 mb-2 px-3 flex flex-column justify-content-center">
        <GameStatusDropdown
          value={data?.status}
          store={data?.store}
          bundleId={data?.bundleId}
          studioId={data?.studioId}
          onChange={handleStatusChange}
        />
        <HeaderInfoCard classname="mb-2 mt-1" title="App ID" icon={<CardIcon />}>
          {data?.appId}
        </HeaderInfoCard>
        {!data?.isDraft && (
          <HeaderInfoCard classname="mb-2" title="Game Analytics ID" icon={<CardIcon />}>
            <GameAnalyticsModal data={data} foundStudio={foundStudio} />
          </HeaderInfoCard>
        )}
        {data?.store && (
          <HeaderInfoCard classname="mb-2 mt-1" title="Store" icon={<CardIcon />}>
            {capitalizeFirstLetter(data?.store)}
          </HeaderInfoCard>
        )}
        <Restricted permission="select.studio">
          <HeaderInfoCard classname="mb-2" title="Studio" icon={<StudioIcon />}>
            {foundStudio?.name}
          </HeaderInfoCard>
        </Restricted>
        <div className="d-flex justify-content-between align-items-start">
          <HeaderInfoCard classname="mb-2" title="Contact " icon={<PeopleGroupIcon />}>
            {data?.bizdevEmail}
          </HeaderInfoCard>
          <Restricted permission="game.email.update">
            <InfosPublisherModal infos={data} />
          </Restricted>
        </div>
        <HeaderInfoCard classname="mb-2" title="Created Date" icon={<CalendarIcon />}>
          {moment(data?.createdAt).utc().format('ll')}
        </HeaderInfoCard>
        <HeaderInfoCard classname="mb-2" title="Updated Date" icon={<CalendarIcon />}>
          {moment(data?.updateTime).utc().format('ll')}
        </HeaderInfoCard>
        <HeaderInfoCard classname="mb-2" title="Facebook App ID" icon={<CardIcon />}>
          {(!data?.isDraft || data.fbAppId) &&
            (!data?.fbAppId ? (
              <FbAppIDModal data={data} foundStudio={foundStudio} />
            ) : (
              <div className="d-flex justify-content-between">
                {data.fbAppId}
                <Restricted permission="fb.update">
                  <EditFbAppModal foundStudio={foundStudio} data={data} />
                </Restricted>
              </div>
            ))}
        </HeaderInfoCard>
      </div>
    </div>
  );
};

export default GameInfoWidget;
