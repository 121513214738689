import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useCreateAdSetMutation } from '../../../../../data/api/fbAdsApi';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGameById } from '../../../../../data/slices/studio';

function CreateAdsetModal({ closeModal, campaign, client, onSuccess }) {
  const [adSetName, setAdSetName] = useState('');
  const [dailyBudget, setDailyBudget] = useState('');
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);
  const { id } = useParams();
  const foundGame = useSelector(getGameById(id));
  const [os, setOs] = useState(foundGame.os);
  const [errorMessage, setErrorMessage] = useState('');
  const [createAdSet, { isLoading, isSuccess, isError }] = useCreateAdSetMutation();
  const appUrl =
    os === 'android'
      ? 'https://play.google.com/store/apps/details?gl=US&id=' + foundGame.appId
      : 'https://apps.apple.com/us/app/' + foundGame.appName + '/id' + foundGame.appId;
  const handleSave = () => {
    if (!selectionRange) {
      setErrorMessage('Please select a date range.');
      return;
    }
    setErrorMessage('');
    const adSetPayload = {
      name: adSetName,
      clientId: client.id,
      campaignId: campaign.id,
      daily_budget: dailyBudget,
      status: 'ACTIVE',
      start_time: moment(selectionRange.startDate).toISOString(),
      end_time: moment(selectionRange.endDate).toISOString(),
      optimization_goal: 'APP_INSTALLS',
      lifetime_budget: 0,
      promoted_object: {
        application_id: foundGame?.fbAppId,
        object_store_url: appUrl,
      },
      targeting: {
        age_max: 65,
        age_min: 18,
        app_install_state: 'not_installed',
        brand_safety_content_filter_levels: ['FACEBOOK_STANDARD', 'AN_STANDARD'],
        targeting_automation: {
          advantage_audience: 1,
        },
        user_os: os === 'android' ? ['Android'] : ['iOS'],
        geo_locations: {
          countries: ['US'],
          location_types: ['home', 'recent'],
        },
      },
    };

    createAdSet(adSetPayload)
      .unwrap()
      .then((newAdSet) => {
        if (newAdSet.error) {
          let msg = `${newAdSet.error?.errorUserTitle}: ${newAdSet.error?.errorUserMsg}`;
          setErrorMessage(
            newAdSet.error?.errorUserTitle
              ? msg
              : newAdSet.error.message || 'Failed to create campaign. Please try again.',
          );
        } else {
          onSuccess(newAdSet);
          closeModal();
        }
      })
      .catch((error) => {
        console.error('Failed to create adset:', error);
        setErrorMessage('Failed to create campaign. Please try again.');
      });
  };

  const handleOpenDateRangeModal = () => {
    setShowDateRangeModal(true);
  };

  const handleDateRangeSubmit = () => {
    setShowDateRangeModal(false);
  };

  return (
    <>
      <Modal show onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Adset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAdsetName">
              <Form.Label>Adset Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter adset name"
                value={adSetName}
                onChange={(e) => setAdSetName(e.target.value)}
                required
              />
            </Form.Group>
            <div>
              {foundGame.multipleOs && (
                <div className="col-12 mt-1">
                  <div
                    className="d-flex justify-content-start align-items-center cursor-pointer"
                    onClick={() => setOs(os === 'android' ? 'ios' : 'android')}
                  >
                    <div className="fs-7 text-muted me-1">Android</div>
                    <Form>
                      <Form.Check type="switch" checked={os === 'ios'} id="custom-switch" />
                    </Form>
                    <div className="fs-7 text-muted" id="parot-ios-icon">
                      IOS
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              {os === 'android' ? (
                <>
                  <div>Bundle ID:</div>
                  <div>{foundGame.bundleId}</div>
                </>
              ) : (
                <>
                  <div>App ID:</div>
                  <div>{foundGame.appId}</div>
                </>
              )}
              <Link
                className=""
                to={{
                  pathname: appUrl,
                }}
                target="_blank"
              >
                check Store url
              </Link>
            </div>

            <Form.Group controlId="formDailyBudget">
              <Form.Label>Daily Budget in USD</Form.Label>
              <div className="input-group">
                <span className="input-group-text pe-0">$</span>
                <Form.Control
                  className="ps-1"
                  type="number"
                  step=".01"
                  placeholder="Enter daily budget"
                  value={dailyBudget / 100}
                  onChange={(e) => setDailyBudget(e.target.value * 100)}
                  required
                  min="1"
                />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label className="mt-2">Start Time - End Time</Form.Label>
              <Button
                className="bg-light-400 border-0 w-100 d-flex justify-content-start"
                onClick={handleOpenDateRangeModal}
              >
                {!selectionRange
                  ? 'Select Date Range'
                  : `${moment(selectionRange.startDate).format('MM/DD/YYYY')} - ${moment(selectionRange.endDate).format('MM/DD/YYYY')}`}
              </Button>
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Adset'}
          </Button>
        </Modal.Footer>
        {!errorMessage && isSuccess && <p className="text-success ms-4">Adset created successfully!</p>}
        {errorMessage && isError && <p className="text-danger ms-4">Failed to create adset.</p>}
      </Modal>

      <Modal centered show={showDateRangeModal} onHide={() => setShowDateRangeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-around w-100">
              <p className="fs-7 m-0">Start date</p>
              <p className="fs-7 m-0">End date</p>
            </div>
            <DateRange
              ranges={[selectionRange || { startDate: new Date(), endDate: new Date(), key: 'selection' }]}
              onChange={(ranges) => setSelectionRange(ranges.selection)}
              minDate={new Date()}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowDateRangeModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDateRangeSubmit}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateAdsetModal;
